(function ($) {

  $(function() {
    const common = new Common();
  })

  class Common {

    $body: JQuery;
    matchMedia: MediaQueryList;

    constructor() {
      this.$body = $('body');
      this.matchMedia = matchMedia('(min-width: 1024px)');
      this._init();
    }

    _init() {
      this._rollOver(); // 画像ロールオーバー
      this._currentNavi01(); // 横ナビゲーション現在位置
      this._currentNavi02(); // 縦ナビゲーション現在位置
      this._openLinkTargetBlank(); // 外部リンクを別タブで開く
      this._matchHeight(); // float要素の横並び高さ揃え
      this._smoothScroll(); // ページ内リンクのスムーススクロール
      this._heightLine(); // 要素の高さ揃え
      this._reloadMediaQuery(); // ウインドウ幅を変更しメディアクエリをまたいだらブラウザをリロード

      const that = this;
      (function(mq) {
        if (mq.matches) {
          // for PC
          that._polyfillForEach(); // for IE11
          that._warningLegacyBrowser(); // レガシーブラウザへの警告
          that._fixedGnav(); // スクロール中、グロナビを上部固定
        } else {
          // for SP
          that._toggleSpMenu(); // スマホ用ハンバーガーメニューの開閉
        }
      }(this.matchMedia));
    }

    _polyfillForEach() {
      if ('NodeList' in window && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
          thisArg = thisArg || window;
          for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
          }
        };
      }
    }

    _rollOver() {
      $('a img').each(function() {
        const $this = $(this);
        const src_off = $this.attr('src');
        let src_on = '';
        if (src_off) {
          src_on = src_off.replace(
            /^(.+)_off(\.[^\.]+)$/,
            '$1_on$2'
          );
        }
        $('<img />').attr('src', src_on);
        $this.hover(
          function() {
            $this.attr('src', src_on);
          },
          function() {
            if (src_off) {
              $this.attr('src', src_off);
            }
          }
        );
      });
    }

    _currentNavi01() {
      const pageClass = [
        'thoughtPage',
        'facilityPage',
        'guidePage',
        'flowPage',
        'teacherPage',
        'questionPage',
      ];
      const gnavList = [
        'Thought',
        'Facility',
        'Guide',
        'Flow',
        'Teacher',
        'Question',
      ];

      for (let i = 0, len = $('.gnav01 li').length; i < len; i++) {
        if (this.$body.hasClass(pageClass[i])) {
          $('.gnav01__link' + gnavList[i] + ' > a').addClass('active')
        }
      }
    }

    _currentNavi02() {
      const pageClass = [
        'guardianPage',
        'certificatePage',
        'thoughtPage',
        'saturdayPage',
        'careerPage',
        'contactPage',
        'supportPage',
        'consultingPage'
      ];
      const gnavList = [
        'Guardian',
        'Certificate',
        'Thought',
        'Saturday',
        'Career',
        'Contact',
        'Support',
        'Consulting'
      ];

      for (let i = 0, len = $('.gnav02 li').length; i < len; i++) {
        if (this.$body.hasClass(pageClass[i])) {
          $('.gnav02__link' + gnavList[i]).addClass('active');
        }
      }
    }

    _warningLegacyBrowser() {
      if (!Modernizr.borderradius) {
        this.$body.prepend(
          '<div class="warningLegacyBrowser">お使いのブラウザは古いブラウザです。快適な閲覧と安全のために、ブラウザのアップデートをおすすめします。　→<a href="//browsehappy.com/">http://browsehappy.com/</a></div>'
        );
      }
    }

    _openLinkTargetBlank() {
      $('a').each(function() {
        const href = $(this).attr('href');
        if (href === undefined) { return; }
        if (href.indexOf('http') === 0 || href.indexOf('//') === 0) {
          $(this).attr('target', '_blank');
        }
      });
    }

    _matchHeight() {
      // (<any>$('.teacherPage .teacher__list')).matchHeight();
    }

    _smoothScroll() {
      $('a[href^=#]').on('click', function() {
        const speed = 500;
        const href= $(this).attr('href');
        if (href === undefined) { return; }
        const target = $(href == '#' || href == '' ? 'html' : href);
        if (target === undefined) { return; }
        const position = target.offset()!.top;
        $('html, body').animate({scrollTop:position}, speed, 'swing');
        return false;
      });
    }

    _fixedGnav() {
      const $gnav01 = $('.gnav01');
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
      }
      const callback = (entries: any, observer: any) => {
        entries.forEach((entry:any) => {
          if (entry.isIntersecting) {
            this.$body.removeClass('gnav--fixed');
          } else {
            this.$body.addClass('gnav--fixed');
          }
        });
      }
      const observer = new IntersectionObserver(callback, options);
      const target: any = document.querySelector('#js-gnav01Pos');
      observer.observe(target);
    }

    _heightLine() {
      // 1日の流れ
      (<any>$('.flowPage'))
        .find('.lowContainer > .flowContainer__inner')
        .heightLine();
    }

    _toggleSpMenu() {
      const $gnavSp = $('.gnavSp'),
        $html = $('html');

      let flg = true,
        pos = 0;
      $('.js-gnavSp_btn').on('click', function() {
        if (flg) {
          pos = Number($(window).scrollTop());
          $gnavSp.addClass('is-open');
          $html.addClass('is-fixed');
          flg = false;
        } else {
          $gnavSp.removeClass('is-open');
          $html.removeClass('is-fixed');
          if (pos !== NaN) {
            window.scrollTo(0, pos);
          }
          flg = true;
        }
      });
    }

    _reloadMediaQuery() {
      this.matchMedia.addListener(_reloadBrowser);
      function _reload() {
        location.reload();
      }
      function _reloadBrowser(mq: any) {
        if (mq.matches) {
          _reload();
        } else {
          _reload();
        }
      }
    }

  }

})(jQuery);
